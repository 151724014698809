import React from 'react'
import {Form} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
const FilterInput = (props) => {
  const {field, id, options, selected, placeholder, handleOnChange, display} = props;
  if (!display) return null

  return (
    <Form.Group id={field}>
      <Form.Label>{field}</Form.Label>
      <Form.Select 
        placeholder={placeholder}
        value={selected}
        onChange={(value) => handleOnChange(value, field)}
      >
        <option>{placeholder}</option>
        {options.map((option) => (
          <option value={option} key={option}>{option}</option>
        ))}
      </Form.Select>
    </Form.Group>
  )

  return (
    <div>
      <Form.Label>{field}</Form.Label>
        <Typeahead
          clearButton
          id={id}
          labelKey={field}
          onChange={(value) => handleOnChange(value, field)}
          options={options}
          placeholder={placeholder}
          selected={selected}
        />
    </div>
  )
}

export default FilterInput